import { getWordpress } from 'PublicAPI';

import { GUIDES_PATH } from '../wordpress';
import { GUIDE_SUMMARY_KEYS } from '../wordpress/@types/guide';

const formatWpData = (body) => {
  const wp = {
    archive: null,
    content: null,
    meta: {
      canonical: null,
      desc: null,
      title: null,
    },
    posts: [],
    title: null,
  };

  if (body.length > 0) {
    const data = body[0];

    wp.title = data.title.rendered;
    wp.content = data.content.rendered;
    wp.meta = {
      canonical: data?.yoast?.yoast_wpseo_canonical,
      desc: data?.yoast?.yoast_wpseo_metadesc,
      instantMatch: !!data?.acf?.display_instant_match_banner,
      published: data?.date,
      title: data?.yoast?.yoast_wpseo_title,
    };
  }
  return wp;
};

const getPage = async (slug, isGuideRoute, isTestingWpEngineAPI = false, headers = null) => {
  const guide = (isGuideRoute) ? '&parent=32' : '';
  return getWordpress(`/wp/v2/pages/?slug=${slug}${guide}`, isTestingWpEngineAPI, headers);
};

export const getGuidePage = async ({ route, slug }) => {
  const isGuideRouteV2 = slug.startsWith(GUIDES_PATH);
  const guideSlug = '/' + (route || slug.replace(/^\/sell-my-car/, '')) // eslint-disable-line prefer-template
    .replace(/^\//, '');

  const rootPage = /^\/guides$/.test(guideSlug);

  if (rootPage) {
    const rootSlug = (isGuideRouteV2) ? 'guides-v2' : 'guides';
    const body = await getWordpress(`/wp/v2/pages/?slug=/${rootSlug}`);
    return { ...formatWpData(body), isGuideRouteV2 };
  }

  const parent = (isGuideRouteV2) ? '9041' : '32';
  const body = await getWordpress(`/wp/v2/pages/?slug=${guideSlug}&parent=${parent}`);
  return { ...formatWpData(body), isGuideRouteV2 };
};

export const fetchPageData = async (slug, isGuideRoute, isTestingWpEngineAPI = false, headers = null) => {
  const body = await getPage(slug, isGuideRoute, isTestingWpEngineAPI, headers); // https://github.com/motorway/motorway-seller-webapp#wordpress-content
  return formatWpData(body);
};

export const getGuidesPreviewData = async () => {
  const guideData = getWordpress('/wp/v2/pages/?parent=32');
  return guideData.then((guides) =>
    guides.map((guide) =>
      Object.fromEntries(Object.entries(guide).filter(([k]) => GUIDE_SUMMARY_KEYS.includes(k)))));
};

export const fetchLandingPage = async (slug, isV2WPPage = false) => {
  const pageSlug = isV2WPPage ? `${slug}-v2` : slug;
  const body = await getPage(pageSlug);

  const wp = {
    archive: null,
    content: null,
    meta: {
      canonical: null,
      desc: null,
      title: null,
    },
    posts: [],
    title: null,
  };

  if (!body[0]) {
    return wp;
  }

  const { acf, yoast } = body[0];

  wp.title = acf.header_title || '';
  wp.meta.title = acf.seo_title || wp.title;
  wp.meta.canonical = yoast?.yoast_wpseo_canonical;
  wp.meta.description = acf.seo_description || acf.header_description || '';
  wp.meta.desc = wp.meta.description;
  wp.content = acf;

  return wp;
};
